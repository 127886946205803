import queryClient from '@constant/queryClient';
import { configureKey } from '@/constant/query-key-factories/configureKey';
import { IConfig } from '@/service/user';
import { stringify } from 'qs';

// 获取 UAA 平台链接

export function getConfigure() {
  return queryClient.getQueryData<IConfig>(configureKey.all);
}

export function getUAA(url: string): string {
  const configure = getConfigure();
  // 连接符
  const sign = location.search ? '&' : '?';
  return `${url}?redirect=${location.href}${sign}clientId=${configure?.clientId}`;
}

export function goToUaa(key?: 'login' | 'register'): void {
  const configure = getConfigure();
  switch (key) {
    case 'login':
      window.location.href = getUAA(configure?.login || '');
      break;
    case 'register':
      window.location.href = getUAA(configure?.register || '');
      break;
    default:
      window.location.href = getUAA(configure?.auth || '');
      break;
  }
}

// 其他平台跳转过来 如果登录先去 uaa 验证一下登录状态
export function otherPlatformLogin(checked: string, query: any) {
  const configure = getConfigure();
  const queryStr = stringify(query);
  switch (checked) {
    // 如果是 已登录 去 uaa
    case '1':
      window.location.href = `${configure?.auth}?redirect=${location.origin}?clientId=${configure?.clientId}&${queryStr}`;
      break;
    // 如果未登录 直接去首页
    case '0':
      window.location.href = queryStr ? `/?${stringify(query)}` : '/';
      break;
    default:
      break;
  }
}

// 测试环境
// http://opendatalab-test.shlab.tech?locales=zh-CN&checked=1
//   http://opendatalab-test.shlab.tech?locales=en-US&checked=0
//
//     生产环境  (未部署)
// https://opendatalab.org.cn?locales=zh-CN&checked=1
//   https://opendatalab.org.cn?locales=en-US&checked=0
//
//     参数说明
// {
//   locales: zh-CN',  中文
//   locales: en-US,  英文
//   checked：1, 已登录
//   checked：0, 未登录
// }

/** todo
 * 1 是否登录由他们自行判断 如果是登录的 直接去鉴权页面 redirect 到我们页面 携带我们的 clientId  如果未登录 直接去 opendatalab.com
 *
 * 2 通过 ssoUid clientId 和后端配合
 * */
