export default {
  'user.welcome': '欢迎来到 OpenDataLab',
  'user.desc': 'OpenDataLab 是有影响力的数据开源开放平台，公开数据集触手可及。',
  'user.signIn': '登录',
  'user.signUp': '注册',
  'user.signOut': '退出登录',
  'user.register': '注册',
  'user.freeRegister': '免费注册',
  'user.email': '邮箱',
  'user.emailErrorHint1': '邮箱不能为空',
  'user.emailErrorHint2': '请输入正确的邮箱',
  'user.password': '密码',
  'user.passwordConfirmation': '确认密码',
  'user.passwordErrorHint': '密码不能为空',
  'user.passwordErrorHint2': '密码包含 数字,英文,字符中的两种及以上，长度8-20。',
  'user.passwordErrorHint3': '两次密码不匹配',
  'user.remember': '七天免登录',
  'user.twoPassword': '两次密码不一致，请重新输入',
  'user.lostPassword': '忘记密码',

  'user.username': '请设置用户名',
  'user.usernameHint': '用户名不能为空',
  'user.usernameHint1': '用户名应为3-20个字符',
  'user.pleaseEmail': '请设置邮箱',
  'user.pleaseEmailHint': '邮箱不能为空',
  'user.pleasePassword': '请设置密码',
  'user.confirmPassword': '请确认密码',
  'user.codePlaceholder': '验证码',
  'user.codeHint': '验证码不能为空',
  'user.sendCode': '获取验证码',
  'user.resend': '重新发送',
  'user.alreadyAccount': '已有账号',
  'user.confirmEmail': '确认您的电子邮件',
  'user.sendEmailMessage': '我们已发送电子邮件至{email}。检查您的收件箱以激活您的帐户。',

  // forget
  'user.inputEmail': '输入邮箱',
  'user.changePassword': '密码修改',
  'user.sendVerificationEmail': '发送验证邮箱',
  'user.forgetPassword': '找回密码',
  'user.backLogin': '返回登录',
  'user.passwordPlaceholder': '请输入新密码',
  'user.passwordPlaceholder2': '请确认新密码',
  'user.emailCodePlaceholder': '请输入邮箱验证码',
  'user.notEmail': '没有收到邮件？',
  'user.successResetPassword': '密码修改成功',
  'user.automaticJump': '{count} 后自动跳转至登录页面',

  'user.agree': '同意{protocol}和{privacy}',
  'user.agreeProtocol': '《用户服务协议》',
  'user.agreePrivacy': '《隐私政策》',
  'user.infoSuccess': '信息更新成功',
};
