import FingerprintJS from '@fingerprintjs/fingerprintjs';
import jscooke from 'js-cookie';

/**
 * 初始化项目添加指纹 用于点赞 查看量
 */
export const fingerprint = () => {
  if (!jscooke.get('odl_anonymous')) {
    const fpPromise = FingerprintJS.load();
    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();
      jscooke.set('odl_anonymous', result.visitorId);
    })();
  }
};
