export default {
  'feedback.header': '问题反馈',
  'feedback.give': '前往反馈',
  'feedback.downloadQuestion': '下载遇到问题',
  'feedback.code': '扫码关注公众号，获取更多平台最新资讯',
  'feedback.type': '反馈类型',
  'feedback.module': '系统模块',
  'feedback.priority': '重要程度',
  'feedback.title': '标题',
  'feedback.titleDesc': '请输入标题',
  'feedback.related': '关联的数据集',
  'feedback.relatedDesc': '请选择关联或搜索数据集',
  'feedback.datasetName': '数据集名称',
  'feedback.datasetNameDesc': '请输入数据集名称',
  'feedback.expandType': '扩充类型',
  'feedback.desc': '反馈内容描述',
  'feedback.upload': '上传图片',
  'feedback.desc2': '请简单描述您反馈的内容',
  'feedback.desc3': '请简单描述您希望扩充的数据集及原因',

  'feedback.typeError': '请选择反馈类型',
  'feedback.moduleError': '请选择系统模块',
  'feedback.expandTypeError': '请选择扩充类型',
  'feedback.titleError': '请输入问题反馈的标题',

  'feedback.success': '感谢您的反馈，网站人员会尽快处理，谢谢！',
  'feedback.successDesc': '扫码关注公众号，获取更多平台最新资讯',

  // v1.7.1
  'feedback.opinion': '若您在平台使用过程中有任何建议或意见',
  'feedback.opinionDesc': '迎您填写以下内容进行反馈、或添加{name}直接联系我们',
  'feedback.opinionName': '小助手微信',
};
