export default {
  'home.title': 'OpenDataLab 为国产大模型提供高质量的开放数据集',
  'home.titleDesc': '消除数据孤岛,可免费高速下载海量统一格式的数据集,让数据触手可及',

  'home.title2': '引领AI大模型时代的数据开源开放平台',

  'home.platform': '平台能力',
  'home.platformA': '海量优质多模态',
  'home.platformADesc': '30+的应用场景，20+的标注类型，5+的数据类型',
  'home.platformB': '数据格式标准化',
  'home.platformBDesc': '统一数据集组织结构标准与标注格式标准、在线可视',
  'home.platformC': '开放共享',
  'home.platformCDesc': '无限制的获取与使用数据集，助力AI开发落地',
  'home.platformD': '智慧检索',
  'home.platformDDesc': '输入数据集的名称、简介、应用场景、标注类型等关键字智能查找',
  'home.platformE': '结构化描述可视化展示',
  'home.platformEDesc': '数据集信息结构化梳理，标注及数据分布可视化展示、便于阅读筛选',
  'home.platformF': '免费高速下载',
  'home.platformFDesc': '无需VPN，国内云端下载，高速便捷',
  'home.partners': '合作伙伴',

};
