import { goToUaa } from '@/utils/getUAA';
import { message } from 'antd';
import type {
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import axiosRequest from 'axios';
import { history } from '@umijs/max';

export const prefix = '/api';

const getConfig = (config: InternalAxiosRequestConfig) => {
  return config;
};

const axios = axiosRequest.create({
  timeout: 60 * 1000,
  // cancelToken: new axios.CancelToken((cancel: Canceler) => {}),
});

// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    return getConfig(config);
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);

const responseSuccess = (res: AxiosResponse) => res.data.data;
const responseError = (error: AxiosError) => {
  const { response } = error;
  // 去到登录页面
  switch (response?.status) {
    case 401:
      goToUaa();
      break;
    case 403:
      history.push('/');
      break;
    case 429: // 请求过多
      history.push('/403');
      break;
  }
  // @ts-ignore
  const msg = response?.data?.msg || response?.statusText;
  msg && message.error(msg);
  return Promise.reject(error);
};

// 响应拦截器
axios.interceptors.response.use(responseSuccess, responseError);
export default axios;
