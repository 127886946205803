export default {
  'datasetsDetail.content': '内容',
  'datasetsDetail.code': '代码',
  'datasetsDetail.comments': '评论',
  'datasetsDetail.pleaseEnter': '请输入',
  'datasetsDetail.loginComments': '请登录后发表评论',
  'datasetsDetail.reply': '回复',
  'datasetsDetail.replyTo': '回复{name}',
  'datasetsDetail.cancelReply': '取消回复',
  'datasetsDetail.citationDesc': '请在引用数据集时，使用以下引文',

  'datasetsDetail.introduction': '简介',
  'datasetsDetail.preview': '预览',
  'datasetsDetail.relatedPapers': '相关论文',
  'datasetsDetail.citation': '引文',
  'datasetsDetail.classDefinitions': '类定义',
  'datasetsDetail.dataView': '数据统计',
  'datasetsDetail.tagProgress': '标注分布',
  'datasetsDetail.objectCount': '数据标签分布',
  'datasetsDetail.dataType': '数据类型分布',
  'datasetsDetail.fileSize': '文件大小分布',

  'datasets.source': '来源',

  'datasets.summary': '数据集信息',
  'datasets.numberFile': '文件数量',
  'datasets.license': '许可证',
  'datasets.termsLicense': '许可条款',

  'datasets.publish': '发布信息',
  'datasets.organization': '发布机构',
  'datasets.releaseTime': '发布时间',
  'datasets.homepage': '发布首页',
  'datasets.homepage2': '首页',

  'datasetsDetail.tags': '标签信息',
  'datasetsDetail.fileName': '文件名',
  'datasetsDetail.size': '大小',
  'datasetsDetail.number': '数量',

  'datasetsDetail.profession': '您的身份',
  'datasetsDetail.student': '学生',
  'datasetsDetail.employee': '企业职员',
  'datasetsDetail.researcher': '高校研究员',
  'datasetsDetail.freelancer': '自由职业者',

  'datasetsDetail.purpose': '下载用途',
  'datasetsDetail.reproduction': '算法复现 ',
  'datasetsDetail.testing': '项目测试',
  'datasetsDetail.training': '模型训练',
  'datasetsDetail.publishing': '论文发表',

  'datasetsDetail.expandDataset': '您希望扩充哪些数据集',
  'datasetsDetail.driving': '智能驾驶',
  'datasetsDetail.security': '智慧安防',
  'datasetsDetail.AIEducation': 'AI教育',
  'datasetsDetail.retail': '智慧零售',
  'datasetsDetail.finance': '智慧金融',
  'datasetsDetail.automation': '智能家居',
  'datasetsDetail.moreModel': '物体检测、人脸识别、OCR等通用模型数据',

  'datasets.tableNodataLight': '前往官网',
  'datasets.tableNodata': '本站暂不提供直接下载，如需下载请至数据集官网',

  'datasetDetail.protocolLink1': '平台用户服务协议、',
  'datasetDetail.protocolLink2': '隐私政策',
  'datasetDetail.downloadLicense': '下载此数据集、请你同意并阅读许可证要求、',

  'datasetDetail.errorHint1': '请选择身份',
  'datasetDetail.errorHint2': '请选择下载用途',
  'datasetDetail.errorHint3': '请选择数据集',
  'datasetDetail.infringementTitle': '声明',
  'datasetDetail.infringement': '本网站提供数据仅供学术交流，请勿商用，如有任何建议请邮件联系平台，我们会及时处理。',
  'datasetDetail.contact': '联系我们',

  'datasetDetail.noComment': '留下第一条评论',
  'datasetDetail.checkDocs': '查看详细文档',
  // v1.5
  'datasetDetail.editDataset': '编辑数据集',
  'datasetDetail.addDataType': '添加或删除数据类型',
  'datasetDetail.addTaskType': '添加或删除任务类型',
  'datasetDetail.addLabelType': '添加或删除标注类型',
  'datasetDetail.licenseUrl': 'License 官网链接',
  'datasetDetail.similarDatasets': '相似数据集',
  'datasetDetail.contribute': '感谢你的贡献、平台人员会及时审核',
  'datasetDetail.validLink': '请输入有效的链接',
  // v1.7
  'datasetDetail.licenseTitle': '确认许可证',

  // 1.7.1
  'datasets.sizeDesc': '解压后数据集大小',
  'datasetsDetail.cli': 'CLI',
  'datasetDetail.infringement1':
    '<strong>图片&标注所有权：</strong>本平台提供数据集图片和标注信息仅用于学术目的，请勿商用。平台本身不具有图片和标注信息的所有权，媒体文件和标注信息使用时请遵循其官网约定。',
  'datasetDetail.infringement2':
    '<strong>不提供担保：</strong>用户使用本数据集时需要承担全部责任，本平台不给予您意图使用数据集所需要的所有许可，其他权利比如肖像权、隐私权可能限制您如何使用作品。',
  'datasetDetail.infringement3':
    '<strong>其他：</strong>如果数据集侵权，或者您有任何建议，请联系平台（<a href="mailto: OpenDataLab@pjlab.org.cn">OpenDataLab@pjlab.org.cn</a>），我们会第一时间进行处理。',
  // v1.9.0
  'datasetDetail.sCluster': 'S 集群存储信息',
  'datasetDetail.decompress': '解压格式',
  'datasetDetail.compression': '压缩格式',
  'datasetDetail.sHelp': 'S 集群数据集使用指引',
  'datasetDetail.copyHint': '复制成功',

  // DSDL
  'dsdl.standard': '平台标准',
  'dsdl.standardDesc':
    '该数据集使用DSDL规范对数据进行规范化处理构建标准化数据池，支持 大模型需求，可以方便地自由组合各种数据进行模型训练 <a href="https://opendatalab.github.io/dsdl-docs/tutorials/dataset_download/" target="_blank" rel="noreferrer">详细使用教程</a>',
  'dsdl.xlab.standardDesc':
    '该数据集使用DSDL规范对数据进行规范化处理构建标准化数据池，支持 大模型需求，可以方便地自由组合各种数据进行模型训练 {dom}',
  'dsdl.descFull':
    'DSDL是一种数据集规范，本文件为DSDL详细标注，保留原始数 据集所有信息，请下载本文件并与本页面中的其他数据集文件配 合一起使用 <a href="https://opendatalab.github.io/dsdl-docs/tutorials/dataset_download/" target="_blank" rel="noreferrer">详细使用教程</a>',
  'dsdl.descLife':
    'DSDL是一种数据集规范，本文件为DSDL精简标注，仅保留原始 数据集用于模型训练、推理验证的核心标注信息，请下载本文件 与本页面中的其他数据集文件配合一起使用 详细使用教程 <a href="https://opendatalab.github.io/dsdl-docs/tutorials/dataset_download/" target="_blank" rel="noreferrer">详细使用教程</a>',
  'dsdl.xlab.descFull':
    'DSDL是一种数据集规范，本文件为DSDL详细标注，保留原始数 据集所有信息，请下载本文件并与本页面中的其他数据集文件配 合一起使用 {dom}',
  'dsdl.xlab.descLife':
    'DSDL是一种数据集规范，本文件为DSDL精简标注，仅保留原始 数据集用于模型训练、推理验证的核心标注信息，请下载本文件 与本页面中的其他数据集文件配合一起使用 详细使用教程 {dom}',
    'dsdl.standardDataset': '平台标准数据集',
  'dsdl.Apply': '申请',
  'dsdl.ApplyToDownload': '申请下载',
  'dsdl.ApplyTitle': '申请 {name} 数据集权限',
  'dsdl.ApplyTime': '上次申请时间：{time}',
  'dsdl.name': '姓名',
  'dsdl.namePlaceholder': '请输入真实姓名',
  'dsdl.organization': '组织',
  'dsdl.organizationPlaceholder': '请输入组织名称',
  'dsdl.occupation': '职业',
  'dsdl.occupationPlaceholder': '请输入职业身份',
  'dsdl.emailPlaceholder': '请填写您的企业邮箱，用于接收申请结果',
  'dsdl.errorEmailPlaceholder': '邮箱格式不正确',
  'dsdl.purpose': '数据集用途',
  'dsdl.errorPurpose': '请至少选择 {count} 项',
  'dsdl.purposeDesc': '用途详细描述',
  'dsdl.purposeDescPlaceholder': '请详细描述您使用该数据集的用途',
  'dsdl.agreement':
    '提交此申请即代表同意平台<a href="https://openxlab.org.cn/protocol/service" target="_blank" rel="noreferrer">用户服务协议</a>、<a href="https://openxlab.org.cn/protocol/privacy" target="_blank" rel="noreferrer">隐私政策</a>',
  'dsdl.agreement2':
    '提交此申请即代表同意平台<a href="https://openxlab.org.cn/protocol/service" target="_blank" rel="noreferrer">用户服务协议</a>、<a href="https://openxlab.org.cn/protocol/privacy" target="_blank" rel="noreferrer">隐私政策</a>、<a href="{url}" target="_blank" rel="noreferrer">数据集隐私保护协议</a>',
  'dsdl.submitSuccess': '提交成功',
  'dsdl.submitSuccessDesc': '审批结果及下载链接将发送至您的邮箱{email} ，请耐心等待',
  'dsdl.submitSuccessDesc2': '申请结果将在审批后邮件通知，请耐心等待',
  'dsdl.okText': '我知道了',

  // xlab_v3
  'datasetDetail.private': '私有',
  'datasetDetail.public': '公开',
  'datasetDetail.fileUpdate': '文件有更新',
  'datasetDetail.submitPrompt': '请在任务完成后提交',
  'datasetDetail.submitPromptContent': '当前有数据正在上传/解压，请在任务完成后提交',
  'datasetDetail.submitOnly': '仅提交',
  'datasetDetail.submitSuccess': '提交成功',
  'datasetDetail.submitAndPublic': '提交并公开',
  'datasetDetail.submitAndPublicSuccess': '提交并公开成功',
  'datasetDetail.submitInfo': '提交信息',
  'datasetDetail.submitInfoDesc': '示例：更新 README',
  'datasetDetail.submitInfoError': '请输入提交信息',
  'datasetDetail.datasetStatus': '数据集状态',
  'datasetDetail.datasetIntroduce': '数据集介绍',
  'datasetDetail.Details': '数据集详情',
  'datasetDetail.datasetFile': '数据集文件',
  'datasetDetail.setting': '设置',
  'datasetDetail.reviewing': '数据集最新版本审核中',
  'datasetDetail.reviewDesc': '当前向社区用户展示的仍为最近一次审核通过的内容',
  'datasetDetail.reviewPass': '数据集最新版本审核通过',
  'datasetDetail.rejected': '数据集最新版本审核未通过',
  'datasetDetail.rejectedDesc': '驳回原因：存在敏感信息，当前向社区用户展示的仍为最近一次审核通过的内容',
  'datasetDetail.completeDataset': '完善数据集介绍',
  'datasetDetail.completeDatasetDesc': '完善数据集介绍并上传数据，即可公开数据集',
  'datasetDetail.preview': '数据预览',
  'datasetDetail.errPreview': '暂无可预览数据',
  'datasetDetail.analysis': '统计分析',
  'datasetDetail.errAnalysis': '暂无统计视图',
  'datasetDetail.formatDis': '格式分布',
  'datasetDetail.formatAll': '总计',
  'datasetDetail.sizeDis': '大小分布',
  'datasetDetail.resolutionDis': '分辨率分布',
  'datasetDetail.resolutionH': '分辨率-高',
  'datasetDetail.resolutionW': '分辨率-宽',
  'datasetDetail.upload': '上传',
  'datasetDetail.delFile': '确认删除此文件吗？',
  'datasetDetail.delFileContext': '删除后文件将不可找回',
  'datasetDetail.metaInfo': '相关元信息',
  'datasetDetail.baseInfo': '基础信息',
  'datasetDetail.externalDisplay': '用于对外展示',
  'datasetDetail.openSource': '开源协议',
  'datasetDetail.requiredPublic': '公开数据集必填',
  'datasetsDetail.tag': '标签',
  'datasetsDetail.paperUrl': '发布论文',
  'datasetsDetail.dateDesc': '请输入规范的发布时间，格式规范参照：YYYY-MM-DD',
  'datasetsDetail.urlMsg': '请输入正确的链接地址',
  'datasets.visibility': '数据集可见度',
  'datasets.visibilityDesc':
    '当前数据集状态为私有，仅创建人可以查看和编辑数据集内容 {br}  注意：设为公开前请确认介绍信息完善、文件上传完毕、License 填写正确',
  'datasets.visibilityDesc2':
    '当前数据集状态为公开，任何人都可以查看此数据集，仅创建者可以编辑 {br}  注意：设为公开前请确认介绍信息完善、文件上传完毕、License 填写正确',
  'dataset.setPrivate': '设为私有',
  'dataset.setPublic': '设为公开',
  'dataset.delDataset': '删除数据集',
  'dataset.confirmDelDataset': '确定删除数据集吗？',
  'dataset.confirmDelDatasetDesc': '数据集及其所有的相关文件将永久删除，不可撤销',
  'dataset.delDatasetDesc': '请注意：{br}本删除操作不可撤销；{br}数据集及其所有的相关文件将永久地删除',
  'dataset.notPublic': '数据集不可公开',
  'dataset.notPublicDesc': '请上传数据集相关文件，完善介绍信息后选择公开',
  'dataset.notPublicDesc2': '数据集已下架，如需申请重新公开请联系{br}{email}',
  'dataset.notCommitContent': '存在未提交内容？',
  'dataset.notCommitContentDesc': '请提交后公开，社区用户可见最新提交内容',
  'dataset.taskCompletion': '请在任务完成后公开',
  'dataset.taskCompletionDesc': '当前有数据正在上传/解压，请在任务完成后公开',
  'dataset.useDataset': '使用数据集',
  'dataset.cliDown': '{url} 下载数据集',
  'dataset.sdkDown': '{url} 下载数据集',
  'dataset.editIntroduce': '编辑数据集介绍',
  'dataset.editIntroduceTip': '包含 README.md 与 metafile 文件',
  'dataset.fileAuditRejected': '审核驳回',
  'dataset.quit': '确认退出吗？',
  'dataset.quitContent': '退出后编辑的内容将不会保存。',
  'datasetDetail.editDatasetIntroduce': '编辑数据集介绍',
  'dataset.CurrentCatalog': '当前目录'
};
