export default {
  'common.openDataLab': 'OpenDataLab',
  'common.formatted': 'Formatted',
  'common.original': 'Original',
  'common.finish': 'Finish',
  'common.submit': 'Submit',
  'common.confirm': 'Confirm',
  'common.cancel': 'Cancel',
  'common.reset': 'Reset',
  'common.totalItems': 'Total {num} items',
  'common.operate': 'Operate',
  'common.download': 'Download',
  'common.other': 'Other',
  'common.all': 'All',
  'common.delete': 'Delete',
  'common.top': 'TOP',
  'common.404': 'Sorry, the page you visited does not exist.',
  'common.backHome': 'Back Home',
  'common.edit': 'Edit',
  'common.preview': 'Preview',
  'common.clear': 'Clear',
  // v1.9.0
  'common.basic': 'Basic Info',
  'common.email': 'Email',
  'common.phone': 'Phone',
  'common.github': 'GitHub ID',
  'common.pw': 'Password',
  'common.otherInfo': 'Others',
  'common.username': 'UserName',
  'common.org': 'Organization/Company',
  'common.weChat': 'WeChat',
  'common.zhihu': 'ZhiHu',
  'common.save': 'Save',
  'common.copy': 'Copy',
  'common.copySuccess': 'copy successfully',
  'common.loadMore': 'Load more',
  'common.noData': 'No Data',
  'common.prev': 'Prev',
  'common.next': 'Next',
  'common.enter': 'Please enter',
  'common.select': 'Please select',
  'common.status': 'Status',
  'common.know': 'I got it',
  'common.desc': 'Description',
  'common.quit': 'Quit',
};
