// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/build/apps/web/src/models/useUserModel';
import model_2 from '/build/apps/web/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'useUserModel', model: model_1 },
model_2: { namespace: '@@initialState', model: model_2 },
} as const
