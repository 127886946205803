export default {
  'nav.home': '首页',
  'nav.datasets': '数据集',
  'nav.docs': '文档',
  'nav.dataPrepare': '数据准备',
  'nav.dataUpload': '数据上传',
  'nav.material': '知识库',
  'nav.field': '自定义字段',
  'nav.source': '来源配置',
};
