import axios, { prefix } from '@/service/http';
import { EDAttrsType, EDAttrsFormat } from '@/constant/attrs-type';

export interface IAttrsDesc {
  key: string; // api 名称
  rank?: number; // 排序
  name: IPublic.ILanguage; // 对 key 的描述名称
  type: EDAttrsType; // 字段类型
  format?: EDAttrsFormat; // 字段格式 如果是 type === string 才有这个属性
  listed?: boolean; // 是否在列表展示
  indexed?: boolean; // 支持 es 搜索
  listFilter: boolean; // false 首页筛选 暂时只支持 labelType 类型，其他类型没有渲染逻辑
  highlight?: boolean; // 支持高亮
  sortable?: boolean; // 排序
  editable?: boolean; // 是否能编辑
  required?: boolean; // 是否为必填字段
  attrs?: IAttrsDesc[];
}

/**
 * 获取 数据集 attrs 可用字段
 * */
export const getDatasetAttrs = (): Promise<IAttrsDesc[]> => axios.get(`${prefix}/dataset-attrs`);

/**
 * 获取 数据集 attrs 详情
 * */
export const getDatasetAttrsDetail = (key: string): Promise<IAttrsDesc> =>
  axios.get(`${prefix}/dataset-attrs/${key}`);

/**
 * 新建 attrs
 * */
export const postDatasetAttrs = (data: IAttrsDesc) => axios.post(`${prefix}/dataset-attrs`, data);

/**
 * 删除 attrs
 * **/
export const delDatasetAttrs = (key: string) => axios.delete(`${prefix}/dataset-attrs/${key}`);

/**
 * 更新 attrs
 */
export const putDatasetAttrs = (data: IAttrsDesc) => {
  return axios.put(`${prefix}/dataset-attrs/${data.key}`, data);
};
