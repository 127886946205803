export default {
  'home.title': 'OpenDataLab provides high-quality open datasets for large models',
  'home.titleDesc':
    'Eliminate isolated data islands, give free access to high-speed downloads of standardized datasets, and make data accessible',
  'home.title2': 'Pioneering open-source data platform for large AI model era',
  'home.platform': 'Platform Capacity',

  'home.platformA': 'Massive multimodal',
  'home.platformADesc': '30+ application scenarios, 20+ annotation types, and 5+ data types',
  'home.platformB': 'Format standardization',
  'home.platformBDesc':
    'Unified standards for dataset structure, annotation format, and online visualization',
  'home.platformC': 'Open sharing',
  'home.platformCDesc':
    'Unlimited access to data acquisition and usage, promoting development and landing of AI industry',
  'home.platformD': 'Intelligent search',
  'home.platformDDesc':
    'Search by entering keywords such as the name, introduction, application scenario, and annotation type of the dataset',
  'home.platformE': 'Structured presentation',
  'home.platformEDesc':
    'Structured dataset information with visualized annotation and data distribution for easy reading and simple screening',
  'home.platformF': 'High-speed download',
  'home.platformFDesc': 'Fast download from the domestic cloud without VPN requirement',
  'home.partners': 'Partners',

};
