export default {
  'datasets.placeholder': '试试搜索数据集名称、任务类型或标注类型',
  'datasets.datasetsNum': '{num} 数据集',
  'datasets.datasetsSearch': '{name} 的 {num} 个数据集结果',
  'datasets.title': '欢迎来到 OpenDataLab',
  'datasets.describe': 'OpenDataLab 是引领AI大模型时代的开放数据平台，让数据集触手可及。',

  'datasets.aside.modality': '数据类型',
  'datasets.aside.labelling': '标注类型',
  'datasets.aside.task': '任务类型',

  'datasets.formattedDesc': '此数据集已转换为统一格式并提供使用代码。',
  'datasets.formattedClick': '可点击了解统一格式详情文档',
  'datasets.noData': '暂无数据集',
  'datasets.noDataV2': '没有找到匹配的数据集',

  // v1.6
  'datasets.hottest': '热度',
  'datasets.newest': '更新时间',
  'datasets.backTagList': '返回标签列表',

  // 1.7.1
  'datasets.searchTag': '搜索标签',
  'datasets.noResult': '无结果',
  'datasets.recently': '最近浏览',
  'datasets.name': '数据集名称',
  'datasets.dataFilter': '数据过滤',

  // xlab 2023/2/20
  'datasets.center': '数据集中心',
  'datasets.explore': '探索更多精品数据集！',
  'datasets.createYourDataset': '马上创建你的数据集',
  'datasets.createDataset': '创建数据集',
  'datasets.shareDataset': '探索更多精品数据集！',
  'datasets.repo': '仓库',
  'datasets.repoRule': '请输入英文、数字或字符 - _',
  'datasets.repoExample': '示例 COCO2017，创建后不可更改',
  'datasets.repoRule1': '请输入仓库名称',
  'datasets.repoRule2': '不能为纯数字',
  'datasets.repoDesc': '数据集查看、使用的唯一索引，创建后不可更改',
  'datasets.createName': '名称',
  'datasets.createNameRule': '仅支持中英文、数字、- 和 _',
  'datasets.createNameDesc': '用于对外展示',
  'datasets.cover': '数据集封面',
  'datasets.uploadDesc': '封面尺寸：125*125、支持上传PNG、JPG、JPEG、GIF格式的图片，大小应为5M以内',
  'datasets.desc': '数据集描述',
  'datasets.immediateCreate': '立即创建',

  // 列表页
  'private.noDatasetSpace': '暂未使用数据集空间',
  // 新建
  'private.nameCheck': '数据集名称不能为空',
  'private.nameTips': '在同一空间下作为数据集查看、使用、开发的唯一索引',
  'private.datasetCover': '数据集封面',
  'private.uploadCover': '上传封面',
  'private.coverErr': '只允许上传png、jpg、jpeg、gif后缀的图片',
  'private.coverErrSpec': '请上传符合格式的封面图片',
  'private.datasetDesc': '数据描述',
  'private.datasetDescPlaceholder': '请输入数据描述信息（500个字）',
  'private.typeInfo': '类型信息',
  'private.createNow': '立即新建',

  // 数据上传
  'private.uploadData': '数据上传',
  'private.selectFile': '选择文件',
  'private.selectFolder': '选择文件夹',
  'private.selectArchive': '选择压缩包',
  'private.archiveHint': '选择压缩包上传的压缩文件会自动解压，原压缩文件仍保留',
  'private.uploadDesc': '单个文件不超过4GB，总数量不超过10000，总大小不超过50GB，压缩包仅支持zip',
  'private.readCount': '共读取 {count} 个文件',
  'private.uploadNow': '立即上传',
  'private.fileSizeErr': '单文件大小超过4GB',
  'private.allFileSizeErr': '文件总大小超过50GB',
  'private.allFileCountErr': '文件总数量超过10000',
  'private.fileTypeErr': '文件类型不正确',
  'private.exitUpload': '确定退出数据上传？',
  'private.exitUploadHint': '已传输的数据内容将不会被保存',
  'private.uploadSpec': '上传规范：支持5M以内的 png、jpg、jpeg、gif 格式图片',

  // 任务列表
  'private.taskList': '任务列表',
  'private.uploadList': '上传列表',
  'private.unpackList': '解压列表',
  'private.upload.prompt': '刷新或关闭浏览器会取消当前上传任务，并清除全部记录。',
  'private.upload.all': '全部',
  'private.uploading': '上传中',
  'private.uploadSuccess': '上传成功',
  'private.uploadError': '上传失败',
  'private.unpacking': '解压中',
  // 等待中
  'private.waiting': '等待中',
  'private.unpackSuccess': '解压成功',
  'private.unpackError': '解压失败',
  'private.unpackTime': '解压时间',
  'private.reUpload': '重新上传',
  'private.reUnpack': '重新解压',

  // success
  'private.success.datasetCreate': '数据集创建成功',
  'private.success.datasetUpdate': '数据集更新成功',
  'private.success.returnDetail': '返回详情页',
  'private.success.upload': '上传数据'
};
