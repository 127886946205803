import common from './en-US/common';
import datasets from './en-US/datasets';
import datasetsDetail from './en-US/datasets.detail';
import docs from './en-US/docs';
import feedback from './en-US/feedback';
import footer from './en-US/footer';
import home from './en-US/home';
import material from './en-US/material';
import nav from './en-US/nav';
import user from './en-US/user';
import applyDownload from './en-US/applydownload';

export default {
  ...common,
  ...nav,
  ...footer,
  ...user,
  ...home,
  ...datasets,
  ...datasetsDetail,
  ...docs,
  ...feedback,
  ...material,
  ...applyDownload
};
