export default {
  'feedback.header': 'Give Feedback',
  'feedback.give': 'Give feedback',
  'feedback.downloadQuestion': 'Have download problems?',
  'feedback.code': 'Scan the QR code to follow the official account for further updates of the platform.',
  'feedback.type': 'Feedback Type',
  'feedback.module': 'System Module',
  'feedback.priority': 'Priority Level',
  'feedback.title': 'Title',
  'feedback.titleDesc': 'Please enter a title',
  'feedback.related': 'Related Dataset',
  'feedback.relatedDesc': 'Please select or search datasets',
  'feedback.datasetName': 'dataset name',
  'feedback.datasetNameDesc': 'Please enter dataset name',
  'feedback.expandType': 'Expand Type',
  'feedback.desc': 'Feedback content description',
  'feedback.upload': 'Upload Image',
  'feedback.desc2': 'Please briefly describe your feedback content.',
  'feedback.desc3': 'Please briefly describe the dataset you would like to expand and your reasons.',

  'feedback.typeError': 'Please select a feedback type',
  'feedback.moduleError': 'Please select system module(s)',
  'feedback.titleError': 'Please enter the title of your problem feedback.',
  'feedback.expandTypeError': 'Please select expand types',

  'feedback.success': 'Thank you for your feedback. The site staff will process it promptly. Thank you!',
  'feedback.successDesc': 'Scan the QR code to follow the official account for further updates of the platform.',
  'feedback.opinion': 'If you have any suggestions or comments during the use of the platform',
  'feedback.opinionDesc': 'you are welcome to fill in the following content for feedback、or contact {name} via WeChat',
  'feedback.opinionName': 'us'
};
