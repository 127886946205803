export default {
  'datasets.placeholder': 'Try searching by dataset name, task type, or annotation type',
  'datasets.datasetsNum': '{num} Datasets',
  'datasets.datasetsSearch': '{num} Datasets results for {name}',
  'datasets.title': 'Welcome to OpenDataLab',
  'datasets.describe':
    'OpenDataLab is a pioneering open data platform for the large AI model era, making datasets  accessible.',

  'datasets.aside.modality': 'Data type',
  'datasets.aside.labelling': 'Label type',
  'datasets.aside.task': 'Task type',

  'datasets.formattedDesc': 'This dataset has been converted to a standardized format with the code provided.',
  'datasets.formattedClick': 'View detailed documentation',

  'datasets.noData': 'No Datasets',
  'datasets.noDataV2': 'No matching datasets found',

  // v1.6
  'datasets.hottest': 'Hottest',
  'datasets.newest': 'Newest',
  'datasets.backTagList': 'Back to tag list',

  // 1.7.1
  'datasets.searchTag': 'Search tags',
  'datasets.noResult': 'No results',
  'datasets.recently': 'Recently viewed',
  'datasets.name': 'Dataset name',
  'datasets.dataFilter': 'Data filtering',

  // xlab 2023/2/20
  'datasets.center': 'OpenDataLab',
  'datasets.explore': 'Explore more awesome datasets!',
  'datasets.createYourDataset': 'Create your dataset now',
  'datasets.createDataset': 'Create Dataset',
  'datasets.shareDataset': 'Explore more quality datasets!',
  'datasets.repo': 'Repository',
  'datasets.repoRule': 'Please enter English, numbers or characters -_',
  'datasets.repoExample': 'Example COCO2017, cannot be changed once created',
  'datasets.repoRule1': 'Please enter the repository name',
  'datasets.repoRule2': 'Cannot be pure numbers',
  'datasets.repoDesc': 'The unique index for viewing and using the dataset, which cannot be changed after creation',
  'datasets.createName': 'Name',
  'datasets.createNameRule': '', //不需要限制了
  'datasets.createNameDesc': 'Used for display',
  'datasets.cover': 'Dataset cover',
  'datasets.uploadDesc':
    'Cover size: 125*125; support uploading images in PNG, JPG, JPEG, and GIF formats, with a size of up to 5M',
  'datasets.desc': 'Dataset description',
  'datasets.immediateCreate': 'Create now',

  // Data upload
  'private.uploadData': 'Data upload',
  'private.selectFile': 'Select file',
  'private.selectFolder': 'Select folder',
  'private.selectArchive': 'Select archive',
  'private.archiveHint':
    'Files uploaded in compressed packages will be automatically decompressed, and the original compressed files will still be retained',
  'private.uploadDesc':
    'Single file should not exceed 4GB, total number should not exceed 10000, total size should not exceed 50GB, only zip archives are supported',
  'private.coverErrSpec': 'Please upload a cover image that meets the format',
  'private.readCount': 'A total of {count} files read',
  'private.uploadNow': 'Upload now',
  'private.fileSizeErr': 'Single file size exceeds 4GB',
  'private.allFileSizeErr': 'Total file size exceeds 50GB',
  'private.allFileCountErr': 'Total number of files exceeds 10000',
  'private.fileTypeErr': 'Incorrect file type',
  'private.exitUpload': 'Confirm to exit data upload?',
  'private.exitUploadHint': 'Transmitted data will not be saved?',
  'private.uploadSpec': 'Upload specifications: 5M or less png, jpg, jpeg, gif format images are supported',

  // Task list
  'private.taskList': 'Task list',
  'private.uploadList': 'Upload list',
  'private.unpackList': 'Unpack list',
  'private.upload.prompt':
    'Refreshing or closing the browser will cancel the current upload task and clear all records.',
  'private.upload.all': 'All',
  'private.uploading': 'Uploading',
  'private.uploadSuccess': 'Upload success',
  'private.uploadError': 'Upload failed',
  'private.unpacking': 'Unpacking',
  // Waiting
  'private.waiting': 'Waiting',
  'private.unpackSuccess': 'Unpack success',
  'private.unpackError': 'Unpack failed',
  'private.unpackTime': 'Unpack time',
  'private.reUpload': 'Re-upload',
  'private.reUnpack': 'Re-unpack',

  // success
  'private.success.datasetCreate': 'Dataset created successfully',
  'private.success.datasetUpdate': 'Dataset updated successfully',
  'private.success.returnDetail': 'Return to details page',
  'private.success.upload': 'Upload data'
};
