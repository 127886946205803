export default {
  'apply.policy.agreeTip': '我已阅读以上数据集隐私协议内容知晓并确认相关内容',
  'apply.policy.agree': '请勾选同意选项',
  'apply.form.label.name': '姓名',
  'apply.form.label.email': '邮箱',
  'apply.form.label.org': '组织',
  'apply.form.label.profession': '职业',
  'apply.form.label.purpose': '数据集用途',
  'apply.form.label.desc': '用途详细描述',
  'apply.form.label.policy': '提交此申请即代表同意',
  'apply.form.label.policy.service': '平台用户服务协议',
  'apply.form.label.policy.privacy': '隐私政策',
  'apply.form.placeholder.email': '请填写您的企业邮箱，用于接收申请结果',
  'apply.form.placeholder.name': '请输入真实姓名',
  'apply.form.placeholder.org': '请输入组织名称',
  'apply.form.placeholder.profession': '请输入您的职业身份',
  'apply.form.placeholder.purpose': '请输入真实姓名',
  'apply.form.placeholder.desc': '请详细描述您使用该数据集的用途',
  'apply.form.rule.email': '请填写有效的邮箱地址',
  'apply.form.required.email': '请输入邮箱',
  'apply.form.required.name': '请输入真实姓名',
  'apply.form.required.org': '请输入组织名称',
  'apply.form.required.profession': '请输入职业身份',
  'apply.form.required.purpose': '请至少选择一项',
  'apply.form.cancel': '取消',
  'apply.button.popover': '申请结果将在审批后邮件通知，请耐心等待',
  'apply.form.purpose.1': '教学与学习',
  'apply.form.purpose.2': '商业用途',
  'apply.form.purpose.3': '科研与论文发表',
  'apply.form.purpose.4': '比赛',
  'apply.form.purpose.5': '其他',
  'apply.form.res.front': '审批结果及下载链接将发送至您的邮箱',
  'apply.form.res.end': '请耐心等待',
  'apply.form.second': '秒',
  'apply.form.second.left': '剩余',
  'apply.form.next': '下一步',
  'apply.form.apply': '申请',
  'apply.form.submit': '申请',
  'apply.form.datasetAuth': '数据集授权',
  'apply.button.applyForDownload': '申请下载'
};
