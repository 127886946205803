// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import {
  Modal,
  ConfigProvider,
  message,
  notification,
} from 'antd';
import { ApplyPluginsType } from 'umi';
import { getPluginManager } from '../core/plugin';

let cacheAntdConfig = null;

const getAntdConfig = () => {
  if(!cacheAntdConfig){
    cacheAntdConfig = getPluginManager().applyPlugins({
      key: 'antd',
      type: ApplyPluginsType.modify,
      initialValue: {
        ...{"theme":{"token":{"colorPrimary":"#1b67ff","colorSuccess":"#00b365","colorWarning":"#ff8800","colorError":"#f5483b","colorBorder":"rgba(0, 0, 0, 0.10)","colorBorderSecondary":"rgba(0, 0, 0, 0.10)","borderRadius":2,"wireframe":true,"colorTextBase":"#000000","colorText":"rgba(0, 0, 0, 0.72)","colorTextSecondary":"rgba(0, 0, 0, 0.60)","colorTextTertiary":"rgba(0, 0, 0, 0.24)"}}},
      },
    });
  }
  return cacheAntdConfig;
}

export function rootContainer(rawContainer) {
  const {
    appConfig,
    ...finalConfigProvider
  } = getAntdConfig();
  let container = rawContainer;
  if (finalConfigProvider.prefixCls) {
    Modal.config({
      rootPrefixCls: finalConfigProvider.prefixCls
    });
    message.config({
      prefixCls: `${finalConfigProvider.prefixCls}-message`
    });
    notification.config({
      prefixCls: `${finalConfigProvider.prefixCls}-notification`
    });
  }

  if (finalConfigProvider.iconPrefixCls) {
    // Icons in message need to set iconPrefixCls via ConfigProvider.config()
    ConfigProvider.config({
      iconPrefixCls: finalConfigProvider.iconPrefixCls,
    });
  };

  if (finalConfigProvider.theme) {
    // Pass config theme to static method
    ConfigProvider.config({
      theme: finalConfigProvider.theme,
    });
  }

  container = <ConfigProvider {...finalConfigProvider}>{container}</ConfigProvider>;


  return container;
}
