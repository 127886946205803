export default {
  'apply.policy.agreeTip': ' I have read, understood, and confirmed the contents of the dataset privacy agreement',
  'apply.policy.agree': 'Please tick the agreement option',
  'apply.form.label.name': 'Name',
  'apply.form.label.email': 'Email',
  'apply.form.label.org': 'Organization',
  'apply.form.label.profession': 'Profession',
  'apply.form.label.purpose': 'Usage',
  'apply.form.label.desc': 'Detailed Description of Use',
  'apply.form.label.policy': 'By submitting this application, you agree to the',
  'apply.form.label.policy.service': "platform's User Service Agreement",
  'apply.form.label.policy.privacy': ' Privacy Policy',
  'apply.form.placeholder.email': 'Please fill in the email. We will feedback the application result to your email',
  'apply.form.placeholder.name': 'Please fill in your name',
  'apply.form.placeholder.org': 'Please fill in your organization',
  'apply.form.placeholder.profession': 'Please fill in your identity',
  'apply.form.placeholder.purpose': 'Please fill in your name',
  'apply.form.placeholder.desc': 'Detailed description of the usage of the dataset',
  'apply.form.rule.email': 'The email format is wrong, please modify it.',
  'apply.form.required.email': 'Please fill in the email',
  'apply.form.required.name': 'Please fill in your name',
  'apply.form.required.org': 'Please fill in your organization',
  'apply.form.required.profession': 'Please fill in your identity',
  'apply.form.required.purpose': 'Please choose the usage of the dataset',
  'apply.form.cancel': 'cancel',
  'apply.button.popover':
    'We will send the application authorization email to the dataset owner, and then feedback the application result to your email',
  'apply.form.purpose.1': 'Educational',
  'apply.form.purpose.2': 'Commercial',
  'apply.form.purpose.3': 'Research and Publication',
  'apply.form.purpose.4': 'Competition',
  'apply.form.purpose.5': 'Others',
  'apply.form.res.front': 'The approval results and download links will be sent to your email,',
  'apply.form.res.end': 'please wait patiently',
  'apply.form.second': 'seconds',
  'apply.form.second.left': 'Remaining',
  'apply.form.next': 'next',
  'apply.form.apply': 'Apply for',
  'apply.form.submit': 'submit',
  'apply.form.datasetAuth': 'Dataset Access',
  'apply.button.applyForDownload': 'Apply to download'
};
