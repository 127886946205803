export default {
  // menu
  'menu.title': '帮助文档',
  'menu.helpDocument': '帮助文档',
  'menu.login': '注册/登录',
  'menu.usageDocument': '产品使用文档',
  'menu.search': '关键词搜索',
  'menu.download': '下载',
  'menu.datasetFormat': '数据集格式',
  'menu.background': '背景介绍',
  'menu.imageFormat': '图像数据集格式',
  'menu.videoFormat': '视频数据集格式',
  'menu.helpDocumentation': '帮助文档',
  'menu.productUsageDocument': '产品使用文档',

  // menu.login
  'docs.welcome': '你好，欢迎使用OpenDataLab！',
  'docs.login.title1': 'OpenDataLab 简介',
  'docs.login.desc1':
    '{name} 为人工智能研究者提供免费开源的数据集，通过OpenDataLab，研究者可以获得格式统一的各领域经典数据集。通过平台的搜索功能，研究者可以迅速便捷地找到自己所需数据集；通过平台的统一格式，研究者可以便捷地对跨数据集任务进行开发。',
  'docs.login.title2': '快速上手',
  'docs.login.desc2': '为了您能够体验平台完整的功能，在使用前，请注册账号并登录。',
  'docs.login.title3': '注册账号并登录使用：',
  'docs.login.desc3_1': '进入OpenDataLab官网，点击右上角【{in}】、【{up}】按钮开始注册',
  'docs.login.desc3_2':
    '在注册界面输入用户名、注册邮箱地址及密码，勾选同意用户协议，并单击【{up}】之后，返回您输入的邮箱，进行【确认】即可完成注册',
  'docs.login.desc3_3': '在登陆界面输入账号及密码，并单击【{in}】即可',
  'docs.likeDesc': '该页面是否有帮助？',

  // menu.search
  'docs.search.desc1': '使用英文关键词快速搜索数据集',
  'docs.search.desc1_1':
    '输入关键字自动遍历数据集名称、数据集简介内容、数据集任务类型、标注类型等内容匹配命中的数据集',
  'docs.search.desc1_2': '支持模糊搜索',
  'docs.search.desc1_3': '关键词联想搜索',
  'docs.search.desc1_4': '分词搜索',

  'docs.search.title1': '多维度筛选数据集',
  'docs.search.title1_1': '数据类型',
  'docs.search.title1_2': '标注类型',
  'docs.search.title1_3': '任务类型',

  'docs.search.title2': '浏览数据集列表页',
  'docs.search.desc2': '数据集核心基本信息一览无余',
  'docs.search.desc2_1': '数据集名称/摘要',
  'docs.search.desc2_2': '数据类型',
  'docs.search.desc2_3': '数据集文件数量',
  'docs.search.desc2_4': '数据量',
  'docs.search.desc2_5': '浏览量/下载量',
  'docs.search.desc2_6': '任务类型',
  'docs.search.desc2_7': '标注类型',

  // menu.download
  'docs.download.desc': '通过云服务为用户提供免费的高速下载功能',
  'docs.download.desc_1': '平台对部分数据集做了格式的统一。',
  'docs.download.desc_2': '对于统一过的数据集，平台提供原始数据集以及统一后数据集的下载。',
  'docs.download.desc_3': '对于未统一的数据集，平台提供原始数据集的下载。',

  'docs.download.title1': '第一步',
  'docs.download.desc1': '进入【数据集详情页】-【下载】模块',
  'docs.download.title2': '第二步',
  'docs.download.desc2': '在数据集下载列表选择要下载的文件，点击【下载】',
  'docs.download.title3': '第三步',
  'docs.download.desc3_1': '确认License，明确数据集使用权限',
  'docs.download.desc3_2':
    '选择身份信息（必选）、下载目的（必选）、希望扩充的数据集、并勾选同意用户协议选项',
  'docs.download.desc3_3': '点击【确认】，即可下载数据集',

  // menu.background
  'docs.background.title1': '为什么要统一数据集格式',
  'docs.background.desc1':
    '目前，学术界和工业界发布的公开数据集之间存在明显格式差异，这对数据集的使用和管理造成了极大不便：',
  'docs.background.desc1_1': '预处理脚本花样百出、共享效率低',
  'docs.background.desc1_2': '数据集理解成本较高',
  'docs.background.desc1_3': '需要同时共享处理脚本',
  'docs.background.desc1_4': '难以跨数据集检索',
  'docs.background.desc1_5': '数据集合并成本较高',
  'docs.background.desc1_6': '难以开发统一可视化工具',
  'docs.background.desc1-1': '为了解决上述问题，OpenDataLab平台对推出的数据集进行了格式的统一。',

  'docs.background.title2': '数据集哪些内容需要统一',
  'docs.background.desc2': '统一格式的内容主要包括数据集组织结构和标注格式两部分。',

  'docs.background.title3': '数据集组织结构',
  'docs.background.desc3':
    '主要规定了数据集的组织形式，即一个完整的数据集应该包含哪些内容，如何保存等内容。按照媒体格式的不同，数据集的组织结构有一定差异。在实际应用中，常见的文件类型包括：',
  'docs.background.desc3_1': '图像数据集',
  'docs.background.desc3_2': '视频数据集',
  'docs.background.desc3_3': '点云/点云+图像数据集 ',

  'docs.background.title4': '标注格式：',
  'docs.background.desc4':
    '主要规定了各种主流标注类型的具体表示格式。比如，图像数据的目标检测标注如何表示等内容。在实际应用中，根据任务的不同，标注的类型也往往有所不同，常见的标注包括：',
  'docs.background.desc4_1': '分类标注',
  'docs.background.desc4_2': '2D矩形标注',
  'docs.background.desc4_3': '多边形标注',
  'docs.background.desc4_4': 'RLE多边形标注',
  'docs.background.desc4_5': '2D折线标注',
  'docs.background.desc4_6': '2D关键点标注',
  'docs.background.desc4_7': '2D点标注',
  'docs.background.desc4_8': '文本标注',
  'docs.background.desc4_9': '语义分割mask标注',
  'docs.background.desc4_10': '实例分割mask标注',
  'docs.background.desc4_11': '全景分割mask标注-TBD',

  // menu.img
  'docs.image.h2': '标准图像数据集格式',

  'docs.image.title1': '图像数据集组织结构',
  'docs.image.desc1': '下面的JSON片断展示了平台采用的图像数据集标注的组织结构。',
  'docs.image.desc1-1': '各个字段的含义如下表所示：',

  'docs.image.title2': '图像数据集标注格式',
  'docs.image.desc2': '下面的JSON片断展示了平台采用的图像数据集标注的组织结构。',
  'docs.image.desc2-1': '各个字段的含义如下表所示：',

  'docs.image.title3': '标注坐标系：',
  'docs.image.desc3': '本标准采用下图所示的像素坐标系：',
  'docs.image.desc3-1':
    '像素坐标系u-v的原点为O，横坐标u和纵坐标v分别是像素所在的列和行。 图中点P的坐标[x, y] = [u1, v1]。',

  'docs.image.title4': '图像数据集标注详解：',
  'docs.image.desc4_1': '2D 矩形标注',
  'docs.image.desc4-2': '分类标注',

  // menu.video
  'docs.video.h2': '标准视频数据集格式',
  'docs.video.title1': '视频数据集组织结构',
  'docs.video.desc1': '下面的JSON片断展示了本标准提出的视频数据集组织结构。',
  'docs.video.desc2': '其中，<sub_dataset_name>字段对应的内容有两种情况：',
  'docs.video.desc3': '1.当频分拆成帧存储时：<media>字段对应的内容为一个两层列表。',
  'docs.video.desc4': '顶层列表代表一个视频。底层列表代表一个视频的所有帧。',
  'docs.video.desc5':
    '2.当视频作为整体存储时：<media>字段对应的内容为一个单层列表，每一个元素代表一个视频。',
  'docs.video.desc6': '关键字段的含义如下表所示：',
  'docs.video.title2': '视频数据集各类标注详解',
  'docs.video.desc7': '下面的JSON片断展示了平台采用的图像数据集标注的组织结构。',

  'docs.like': '感谢您的反馈意见。',

  // v1.6
  'docs.datasetDetail': '数据集详情',
};
