import common from './zh-CN/common';
import datasets from './zh-CN/datasets';
import datasetsDetail from './zh-CN/datasets.detail';
import docs from './zh-CN/docs';
import feedback from './zh-CN/feedback';
import footer from './zh-CN/footer';
import home from './zh-CN/home';
import material from './zh-CN/material';
import nav from './zh-CN/nav';
import user from './zh-CN/user';
import apply from './zh-CN/applydownload';

export default {
  ...nav,
  ...footer,
  ...user,
  ...common,
  ...home,
  ...datasets,
  ...datasetsDetail,
  ...docs,
  ...feedback,
  ...material,
  ...apply
};
