export default {
  'user.welcome': 'Welcome to OpenDataLab',
  'user.desc':
    'OpenDataLab is a leading platform of open-source data, making open datasets accessible.',
  'user.signIn': 'Sign in',
  'user.signUp': 'Sign up',
  'user.signOut': 'Sign Out',
  'user.register': 'Register',
  'user.freeRegister': 'Free Registration',
  'user.email': 'Email address',
  'user.emailErrorHint1': 'Email address cannot be empty',
  'user.emailErrorHint2': 'Please enter your email in a valid format',
  'user.remember': 'Remember me for 7 days',
  'user.password': 'Password',
  'user.passwordConfirmation': 'Password confirmation',
  'user.twoPassword': 'The two passwords are inconsistent',
  'user.passwordErrorHint': 'Password cannot be empty',
  'user.passwordErrorHint2':
    'Password should be 8-20 in length and contains at least two of numbers, letters, and characters',
  'user.passwordErrorHint3': 'Passwords do not match',
  'user.lostPassword': 'Forget Password',

  'user.username': 'Username',
  'user.usernameHint': 'Username cannot be empty',
  'user.usernameHint1': 'Username should be 3-20 characters',
  'user.pleaseEmail': 'Email address',
  'user.pleaseEmailHint': 'Email address cannot be empty',
  'user.pleasePassword': 'Password',
  'user.confirmPassword': 'Confirm password',
  'user.codePlaceholder': 'verification code',
  'user.codeHint': 'Code cannot be empty',
  'user.sendCode': 'send',
  'user.resend': 'Resend',
  'user.alreadyAccount': 'Account already exists',
  'user.confirmEmail': 'Confirm your email',
  'user.sendEmailMessage':
    'We have sent an email to {email}. Check your inbox to activate your account.',

  // forget
  'user.inputEmail': 'Enter Email',
  'user.changePassword': 'Change Password',
  'user.sendVerificationEmail': 'Send email verification code',
  'user.forgetPassword': 'Forget password',
  'user.backLogin': 'Back to sign in',
  'user.passwordPlaceholder': 'New password',
  'user.passwordPlaceholder2': 'Confirm new password',
  'user.emailCodePlaceholder': 'Email verification code',
  'user.notEmail': 'Have not received the email?',
  'user.successResetPassword': 'Password has been reset',
  'user.automaticJump': 'Automatically jump to the login page after {count}',

  'user.agree': 'Agree to the{protocol}and{privacy}',
  'user.agreeProtocol': '“User Service Agreement”',
  'user.agreePrivacy': '“Privacy Policy”',
  'user.infoSuccess': 'Information updated successfully',
};
