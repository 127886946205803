import axios, { prefix } from '@/service/http';
import { ParsedQs } from 'qs';

export interface IUserAuth {
  code: string | string[] | ParsedQs | ParsedQs[];
  redirect: string;
}

export interface IUser {
  email: string;
  name: string;
  phone: string;
  githubAccount: string; // github账号
  role: string; // 用户角色
  id: string | number;
  organization?: string; // 用户所属组织
  wechatAccount?: string; // 微信账号
  zhihuAccount?: string; // 知乎账号
  isInternal?: boolean; // 是否内部用户
}

/*
 * 用户auth授权
 */
export const auth = async (params: IUserAuth) => axios.post(`${prefix}/users/auth`, params);

/**
 * 获取用户信息
 * */
export const getUser = (): Promise<IUser> => axios.get(`${prefix}/users/current`);

/**
 * 修改用户信息
 * */
export const postUserModify = (params: IUser) => axios.post(`${prefix}/users/modify`, params);

/**
 * 退出
 * */
export const logout = () => axios.post(`${prefix}/users/logout`);

export interface IFiling {
  'opendatalab.com': string;
  'opendatalab.org.cn': string;

  [key: string]: string;
}

export interface IConfig {
  filing: IFiling; // 备案号
  clientId: string; // 客户端id
  auth: string; // 授权地址
  login: string; // 登录地址
  register: string; // 注册地址
  origin: string; // 地址
}

/**
 * 获取配置文件
 */
export const getConfig = (): Promise<IConfig> => {
  return fetch(`/config.json`).then((res) => res.json());
};

/**
 * 获取收藏数量
 */
export const getFavoriteCount = (): Promise<{ count: number }> =>
  axios.get(`${prefix}/users/favorite/count`);
