export default {
  // menu
  'menu.title': 'Help Docs',
  'menu.helpDocument': 'Help Documentation',
  'menu.login': 'Registration/Login',
  'menu.usageDocument': 'Product Usage Document',
  'menu.search': 'Keyword Search',
  'menu.download': 'Download',
  'menu.datasetFormat': 'Dataset Format',
  'menu.background': 'Background',
  'menu.imageFormat': 'Image Dataset Format',
  'menu.videoFormat': 'Video Dataset Format',
  'menu.helpDocumentation': 'Help Documentation',
  'menu.productUsageDocument': 'Product Usage Document',

  // menu.login
  'docs.welcome': 'Hello, welcome to OpenDataLab!',
  'docs.login.title1': 'OpenDataLab Introduction',
  'docs.login.desc1':
    'By using {name}, researchers can obtain free formatted datasets in various fields. Through the search function of the platform, researchers may address the dataset they look for quickly and easily. Using the formatted datasets from the platform, researchers can efficiently conduct tasks across datasets.',
  'docs.login.title2': 'Get Started',
  'docs.login.desc2':
    'In order to experience the complete functions of the platform, please register your account and log in before use.',
  'docs.login.title3': 'Register and log in your account：',
  'docs.login.desc3_1':
    'Open the OpenDataLab official website and click the {in} and  {up} buttons',
  'docs.login.desc3_2':
    ' Enter your user name, email address, and password on the registration page, read the user agreement and click {up}, then click confirm to complete the registration',
  'docs.login.desc3_3': 'Enter the account name and password on the login page and click {in}',

  // menu.search
  'docs.search.desc1': 'Quickly search for datasets using keywords in English:',
  'docs.search.desc1_1':
    'Enter keywords to automatically sort through the dataset name, dataset introduction, dataset task type, label type, and other content to address the matching dataset you look for',
  'docs.search.desc1_2': 'Support fuzzy search',
  'docs.search.desc1_3': 'Keyword search for related content',
  'docs.search.desc1_4': 'Tokenized search',

  'docs.search.title1': 'Multidimensional Dataset Filters',
  'docs.search.title1_1': 'Data type.',
  'docs.search.title1_2': 'Annotation type.',
  'docs.search.title1_3': 'Task type.',

  'docs.search.title2': 'Browse Pages of Dataset List',
  'docs.search.desc2': 'The core basic information of the dataset is directly displayed.',
  'docs.search.desc2_1': 'Dataset name / summary',
  'docs.search.desc2_2': 'Data type',
  'docs.search.desc2_3': '- Number of dataset files',
  'docs.search.desc2_4': 'Data quantity',
  'docs.search.desc2_5': 'Views/ downloads',
  'docs.search.desc2_6': 'Task type',
  'docs.search.desc2_7': 'Label type',

  // menu.download
  'docs.download.desc': 'The free high-speed download function is provided through cloud services.',
  'docs.download.desc_1': 'The platform has standardized the format for some datasets',
  'docs.download.desc_2':
    'For formatted datasets, the platform offers download for both the original and processed ones',
  'docs.download.desc_3':
    'For unformatted datasets, the platform provides download access to the original datasets',

  'docs.download.title1': 'Step 1',
  'docs.download.desc1': 'Open the dataset detail page - “Download” module.',
  'docs.download.title2': 'Step 2',
  'docs.download.desc2':
    'Select the files to be downloaded from the dataset download list and click "Download".',
  'docs.download.title3': 'Step 3',
  'docs.download.desc3_1': 'Confirm license and clarify the permission of the dataset',
  'docs.download.desc3_2':
    'Select user identity information (required), download purpose (required), dataset categories that you want to expand, and check to agree on the User Service Agreement',
  'docs.download.desc3_3': 'Click “OK” to download the datasets.',

  // menu.background
  'docs.background.title1': 'Why Standardized Format for Datasets?',
  'docs.background.desc1':
    'Currently, there are apparent differences in data format across public datasets, creating notable inconvenience in both using and managing datasets:',
  'docs.background.desc1_1': 'Different preprocessing scripts lead to low efficiency in sharing',
  'docs.background.desc1_2': 'Hard to understand datasets',
  'docs.background.desc1_3': 'Processed scripts need to be shared at once',
  'docs.background.desc1_4': 'Difficult to retrieve across datasets',
  'docs.background.desc1_5': 'Difficult to merge datasets',
  'docs.background.desc1_6': 'Challenging to develop standardized visualization tools',
  'docs.background.desc1-1':
    'In order to solve the above problems, OpenDataLab platform has standardized the format for released datasets.',

  'docs.background.title2': 'What Content Needs to be Standardized?',
  'docs.background.desc2':
    'The format standardization mainly includes structure framework and annotation format.',

  'docs.background.title3': 'Dataset Structure Framework:',
  'docs.background.desc3':
    'It mainly specifies the framework structure of datasets, that is, what content a complete dataset should include and how to store it. According to different media formats, dataset structures would vary. In practical application, common file types include:',
  'docs.background.desc3_1': 'Image dataset',
  'docs.background.desc3_2': 'Video dataset',
  'docs.background.desc3_3': 'Point cloud/ point cloud + image dataset',
  'docs.likeDesc': 'Was this page useful?',

  'docs.background.title4': 'Annotation Format：',
  'docs.background.desc4':
    'It mainly specifies the representation format of various mainstream annotation types. For example, how to represent the target detection and annotation of image data. During actual application, annotations types vary according to different tasks. Common annotations include：',
  'docs.background.desc4_1': 'Classification annotation',
  'docs.background.desc4_2': '2D rectangle annotation',
  'docs.background.desc4_3': 'Polygon annotation',
  'docs.background.desc4_4': 'RLE Polygon annotation',
  'docs.background.desc4_5': '2D polyline annotation',
  'docs.background.desc4_6': '2D keypoints annotation',
  'docs.background.desc4_7': '2D point annotation',
  'docs.background.desc4_8': 'Text annotation',
  'docs.background.desc4_9': 'Semantic segmentation mask annotation',
  'docs.background.desc4_10': 'Instance segmentation mask annotation',
  'docs.background.desc4_11': 'Panoptic segmentation mask annotation-TBD',

  // menu.img
  'docs.image.h2': 'Standardized Image Dataset Format',

  'docs.image.title1': 'Structure Framework of Image Datasets',
  'docs.image.desc1':
    "The following JSON fragment demonstrates the platform's structure framework of image datasets annotation.",
  'docs.image.desc1-1': 'The definition of each field is displayed in the following table：',

  'docs.image.title2': 'Annotation format of Image Dataset',
  'docs.image.desc2':
    "The following JSON fragment demonstrates the platform's structure framework of image datasets annotation.",
  'docs.image.desc2-1': 'The definition of each field is displayed in the following table:：',

  'docs.image.title3': 'Annotation Coordinate System',
  'docs.image.desc3':
    'This standard adopts the pixel coordinate system shown in the following figure:',
  'docs.image.desc3-1':
    '像素坐标系u-v的原点为O，横坐标u和纵坐标v分别是像素所在的列和行。 图中点P的坐标[x, y] = [u1, v1]。',

  'docs.image.title4': 'Detailed Explanation of Various Image Dataset Annotations',
  'docs.image.desc4_1': '2D rectangular annotation',
  'docs.image.desc4-2': 'Classification annotation',

  // menu.video
  'docs.video.h2': 'Standardized Video Dataset Format',
  'docs.video.title1': 'Structure Framework of Video Dataset',
  'docs.video.desc1':
    'The following JSON fragment demonstrates the standard’s structure framework of video datasets.',
  'docs.video.desc2':
    'There are two scenarios for the content corresponding to the <sub_dataset_name> field：',
  'docs.video.desc3':
    '1.When the video is split into frames for storage：The content corresponding to the <media> field is a two-dimensional list. ',
  'docs.video.desc4':
    'The top list represents a video, and the bottom list represents all frames of a video.',
  'docs.video.desc5':
    '2. When the video is stored as a whole piece：The content corresponding to the <media> field is a  single-layer list, and each element represents a video.',
  'docs.video.desc6': 'The definition of key fields is displayed in the following table:',
  'docs.video.title2': 'Detailed Explanation of Various Video Dataset Annotations',
  'docs.video.desc7':
    "The following JSON fragment demonstrates the platform's structure framework of image datasets annotation.",

  'docs.like': 'Thanks for your feedback.',

  // v1.6
  'docs.datasetDetail': 'Dataset Details',
};
