import { auth, getConfig, getUser, IUser } from '@/service/user';
import { fingerprint } from '@/utils/fingerprint';
import { parse, stringify } from 'qs';
import queryClient from '@constant/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { datasetAttrsKey } from '@/constant/query-key-factories/datasetAttrsKey';
import { userInfoKey } from '@/constant/query-key-factories/userInfoKey';
import { configureKey } from '@/constant/query-key-factories/configureKey';
import { getDatasetAttrs } from '@/service/system.prepare.attrs';
import { setLocale } from '@umijs/max';
import { otherPlatformLogin } from '@/utils/getUAA';
import axios from '@/service/http';
import { initAxios } from '@apis/http';
import 'antd/dist/reset.css';

// 1 2 3 为执行顺序

// 1 渲染之前做权限校验、登录状态、项目运行初始配置,早于patchRoutes运行
export async function render(oldRender: () => void) {
  const { code, clientId, checked, ...query } = parse(location.search, {
    ignoreQueryPrefix: true
  });
  initAxios(axios, '/api');

  // 获取用户信息
  if (code) {
    try {
      await auth({ code, redirect: location.origin });
    } catch (error) {}
    location.href = `${location.pathname}${stringify(query, {
      addQueryPrefix: true
    })}`;
  }

  // 获取所有 自定义字段
  queryClient.fetchQuery(datasetAttrsKey.lists(), getDatasetAttrs, {
    cacheTime: Infinity,
    staleTime: Infinity
  });

  // 获取配置信息
  queryClient
    .fetchQuery(configureKey.all, getConfig, {
      cacheTime: Infinity
    })
    .then(() => {
      // 其他平台过来逻辑
      otherPlatformLogin(checked as string, query);
    });

  oldRender();
}

// 3 这里可以放一些全局信息  比如 用户信息  权限 等
export async function getInitialState(): Promise<{ user: IUser }> {
  const data = await getUser();
  // 更新 InitialState 的时候记得执行 queryClient.invalidateQueries([cUserInfo]) 同步更新
  queryClient.fetchQuery(userInfoKey.all, () => data, {
    cacheTime: Infinity
  });
  return {
    user: data
  };
}

export function rootContainer(container: React.ReactNode) {
  return <QueryClientProvider client={queryClient}>{container}</QueryClientProvider>;
}

// 用户指纹
fingerprint();
