export default {
  'common.openDataLab': 'OpenDataLab',
  'common.formatted': '统一格式',
  'common.original': '原始格式',
  'common.finish': '完成',
  'common.submit': '提交',
  'common.confirm': '确认',
  'common.reset': '重置',
  'common.cancel': '取消',
  'common.totalItems': '共 {num} 条',
  'common.operate': '操作',
  'common.download': '下载',
  'common.other': '其他',
  'common.all': '全部',
  'common.delete': '删除',
  'common.top': '置顶',
  'common.404': '抱歉，您访问的页面不存在。',
  'common.backHome': '回到首页',
  'common.edit': '编辑',
  'common.preview': '预览',
  'common.clear': '清空',
  // v1.9.0
  'common.basic': '基本信息',
  'common.email': '邮箱',
  'common.phone': '手机号',
  'common.github': 'GitHub ID',
  'common.pw': '密码',
  'common.otherInfo': '其他信息',
  'common.username': '用户名',
  'common.org': '组织/公司',
  'common.weChat': '微信',
  'common.zhihu': '知乎账号名称',
  'common.save': '保存',
  'common.copy': '复制',
  'common.copySuccess': '复制成功',
  'common.prev': '上一页',
  'common.next': '下一页',
  'common.loadMore': '加载更多',
  'common.noData': '暂无数据',
  'common.enter': '请输入',
  'common.select': '请选择',
  'common.status': '状态',
  'common.know': '我知道了',
  'common.desc': '描述',
  'common.quit': '退出',
};
