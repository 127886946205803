export default {
  'datasetsDetail.content': 'Content',
  'datasetsDetail.code': 'Code',
  'datasetsDetail.comments': 'Comments',
  'datasetsDetail.pleaseEnter': 'please enter',
  'datasetsDetail.loginComments': 'Please login to comment',
  'datasetsDetail.reply': 'Reply',
  'datasetsDetail.replyTo': 'Reply to {name}',
  'datasetsDetail.cancelReply': 'Cancel reply',
  'datasetsDetail.citationDesc': 'Please quote the following citation when citing the dataset.',

  'datasetsDetail.introduction': 'Introduction',
  'datasetsDetail.preview': 'Preview',
  'datasetsDetail.relatedPapers': 'Related Papers',
  'datasetsDetail.citation': 'Citation',
  'datasetsDetail.classDefinitions': 'Class Definitions',
  'datasetsDetail.dataView': 'Data Overview',
  'datasetsDetail.tagProgress': 'Annotation Progress',
  'datasetsDetail.objectCount': 'Object Count',
  'datasetsDetail.dataType': 'Data Type',
  'datasetsDetail.fileSize': 'File Size Distribution',

  'datasets.source': 'Source',

  'datasets.summary': 'Dataset Summary',
  'datasets.numberFile': 'File Count',
  'datasets.license': 'License',
  'datasets.termsLicense': 'Terms of license',

  'datasets.publish': 'Publish Info',
  'datasets.organization': 'Author',
  'datasets.releaseTime': 'Issue Time',
  'datasets.homepage': 'Homepage',
  'datasets.homepage2': 'Homepage',

  'datasetsDetail.tags': 'Tags',
  'datasetsDetail.fileName': 'File Name',
  'datasetsDetail.size': 'Size',
  'datasetsDetail.number': 'Number',

  'datasetsDetail.profession': 'Your profession',
  'datasetsDetail.student': 'Student',
  'datasetsDetail.employee': 'Employee',
  'datasetsDetail.researcher': 'Researcher',
  'datasetsDetail.freelancer': 'Freelancer',

  'datasetsDetail.purpose': 'Download purpose',
  'datasetsDetail.reproduction': 'Algorithm Reproduction ',
  'datasetsDetail.testing': 'Project Testing',
  'datasetsDetail.training': 'Model Training',
  'datasetsDetail.publishing': 'Paper Publishing',

  'datasetsDetail.expandDataset': 'Which dataset categories do you want to expand',
  'datasetsDetail.driving': 'Autonomous Driving',
  'datasetsDetail.security': 'Intelligent Security',
  'datasetsDetail.AIEducation': 'AI Education',
  'datasetsDetail.retail': 'Smart Retail',
  'datasetsDetail.finance': 'Intelligent Finance',
  'datasetsDetail.automation': 'Home Automation',
  'datasetsDetail.moreModel': 'Object detection, face recognition, OCR, and other general model data',

  'datasetDetail.protocolLink': 'the User Service Agreement, and the Privacy Policy',
  'datasetDetail.protocolLink1': 'User Service Agreement、',
  'datasetDetail.protocolLink2': 'Privacy Policy',
  'datasetDetail.downloadLicense': 'By downloading this dataset, you have read and agree to the License Requirement, ',

  'datasets.tableNodataLight': 'Visit Homepage',
  'datasets.tableNodata':
    'Direct download is currently not available. To download, please visit the dataset homepage. ',

  'datasetDetail.errorHint1': 'Please select profession',
  'datasetDetail.errorHint2': 'Please select download purpose',
  'datasetDetail.errorHint3': 'Please select datasets',

  'datasetDetail.infringementTitle': 'Disclaimer',
  'datasetDetail.infringement':
    'Data on this website is for educational purposes only; commercial use is forbidden. Please contact the site for any suggestions, and we will process them promptly',
  'datasetDetail.contact': 'Contact Us',
  'datasetDetail.noComment': 'To leave the first comment',
  'datasetDetail.checkDocs': 'View detailed documentation',
  // v1.5
  'datasetDetail.editDataset': 'Edit Dataset',
  'datasetDetail.addDataType': 'Add or remove data type',
  'datasetDetail.addTaskType': 'Add or remove task type',
  'datasetDetail.addLabelType': 'Add or remove label type',
  'datasetDetail.licenseUrl': 'License Homepage URL',
  'datasetDetail.similarDatasets': 'Similar Datasets',
  'datasetDetail.contribute': 'Thank you for your contribution. The platform staff will review it promptly.',
  'datasetDetail.validLink': 'Please enter a valid link',
  // v1.7
  'datasetDetail.licenseTitle': 'License confirmation',

  // 1.7.1
  'datasets.sizeDesc': 'The size of the dataset after decompression',
  'datasetsDetail.cli': 'CLI',
  'datasetDetail.infringement1':
    '<strong>Original pictures and picture collections: </strong>The data annotation information on this platform is only used for positioning, and joint publishing is prohibited. The platform does not contain pictures and the time for providing picture information. Please follow the Homepage agreement for media files and picture information.',
  'datasetDetail.infringement2':
    '<strong>No location is provided: </strong>You need to take full responsibility for the use of this platform, all your privacy rights and the permissions required to use your data set, other rights are similar to portraits, and the right to restrict the use of works.',
  'datasetDetail.infringement3':
    '<strong>Others: </strong>If the dataset laboratory, or you have any suggestions, please contact the platform （<a href="mailto: OpenDataLab@pjlab.org.cn">OpenDataLab@pjlab.org.cn</a>）, we will process them promptly',
  // v1.9.0
  'datasetDetail.sCluster': 'S Cluster Dataset Info',
  'datasetDetail.decompress': 'Uncompressed format',
  'datasetDetail.compression': 'Compressed format',
  'datasetDetail.sHelp': 'S cluster dataset usage guide:',
  'datasetDetail.copyHint': 'Successfully copied',

  // DSDL
  'dsdl.standard': 'Platform Standard',
  'dsdl.standardDesc':
    'This dataset is normalized using the DSDL specification to construct a standardized data pool, which supports large model requirements and allows for easy and flexible combination of various data for model training. <a href="https://opendatalab.github.io/dsdl-docs/tutorials/dataset_download/" target="_blank" rel="noreferrer">detailed usage tutorial</a>',
  'dsdl.xlab.standardDesc':
    'This dataset is normalized using the DSDL specification to construct a standardized data pool, which supports large model requirements and allows for easy and flexible combination of various data for model training.{dom}',
  'dsdl.descFull':
    'DSDL is a dataset specification. This file contains DSDL detailed annotations and preserves all the information from the original dataset. Please download this file and use it in conjunction with the other dataset files on this page. <a href="https://opendatalab.github.io/dsdl-docs/tutorials/dataset_download/" target="_blank" rel="noreferrer">detailed usage tutorial</a>',
  'dsdl.descLife':
      'DSDL is a dataset specification. This file contains DSDL core annotations, which retains only the core annotation information of the original dataset for model training, inference, and validation. Please download this file and use it in conjunction with other dataset files on this page. <a href="https://opendatalab.github.io/dsdl-docs/tutorials/dataset_download/" target="_blank" rel="noreferrer">detailed usage tutorial</a>',
  'dsdl.xlab.descFull':
    'DSDL is a dataset specification. This file contains DSDL detailed annotations and preserves all the information from the original dataset. Please download this file and use it in conjunction with the other dataset files on this page. {dom}',
  'dsdl.xlab.descLife':
    'DSDL is a dataset specification. This file contains DSDL core annotations, which retains only the core annotation information of the original dataset for model training, inference, and validation. Please download this file and use it in conjunction with other dataset files on this page. {dom}',
    'dsdl.standardDataset': 'Platform Standard Dataset',
  'dsdl.Apply': 'Apply',
  'dsdl.ApplyToDownload': 'Apply to Download',
  'dsdl.ApplyTitle': 'Apply for {name} Dataset Access',
  'dsdl.ApplyTime': 'Last Application Time: {time}',
  'dsdl.name': 'Name',
  'dsdl.namePlaceholder': 'Please Enter Your Real Name',
  'dsdl.organization': 'Organization',
  'dsdl.organizationPlaceholder': 'Please Enter Your Organization Name',
  'dsdl.occupation': 'Occupation',
  'dsdl.occupationPlaceholder': 'Please Enter Your Occupation Identity',
  'dsdl.emailPlaceholder': 'Please Fill in Your Corporate Email Address for Receiving Application Results',
  'dsdl.errorEmailPlaceholder': 'Incorrect Email Format',
  'dsdl.purpose': 'Dataset Purpose',
  'dsdl.errorPurpose': 'Please Select at Least {count} Items',
  'dsdl.purposeDesc': 'Detailed Purpose Description',
  'dsdl.purposeDescPlaceholder': 'Please Describe in Detail the Purpose of Using This Dataset',
  'dsdl.agreement':
    'By submitting this application, you agree to the <a href="https://openxlab.org.cn/protocol/service" target="_blank" rel="noreferrer">Platform Service Agreement</a>, <a href="https://openxlab.org.cn/protocol/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>.',
  'dsdl.agreement2':
    'By submitting this application, you agree to the <a href="https://openxlab.org.cn/protocol/service" target="_blank" rel="noreferrer">Platform Service Agreement</a>, <a href="https://openxlab.org.cn/protocol/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>, and <a href="{url}" target="_blank" rel="noreferrer">Dataset Privacy Protection Agreement</a>.',
  'dsdl.submitSuccess': 'Submission Successful',
  'dsdl.submitSuccessDesc':
    'The approval result and download link will be sent to your email {email}, please be patient.',
  'dsdl.submitSuccessDesc2': 'The application result will be notified by email after approval, please be patient.',
  'dsdl.okText': 'Got It',

  // xlab_v3
  'datasetDetail.private': 'Private',
  'datasetDetail.public': 'Public',
  'datasetDetail.fileUpdate': 'File updated',
  'datasetDetail.submitPrompt': 'Please submit after the task is completed',
  'datasetDetail.submitPromptContent':
    'Data is currently being uploaded/unzipped, please submit after the task is completed',
  'datasetDetail.submitOnly': 'Submit only',
  'datasetDetail.submitSuccess': 'Submission successful',
  'datasetDetail.submitAndPublic': 'Submit and make public',
  'datasetDetail.submitAndPublicSuccess': 'Submission and publicization successful',
  'datasetDetail.submitInfo': 'Submit information',
  'datasetDetail.submitInfoDesc': 'Example: Update README',
  'datasetDetail.submitInfoError': 'Please enter submission information',
  'datasetDetail.datasetStatus': 'Dataset status',
  'datasetDetail.datasetIntroduce': 'Dataset introduction',
  'datasetDetail.Details': 'Dataset details',
  'datasetDetail.datasetFile': 'Dataset file',
  'datasetDetail.setting': 'Settings',
  'datasetDetail.reviewing': 'Latest version of the dataset is under review',
  'datasetDetail.reviewDesc':
    'The content currently shown to the community users is still the content of the last approved review',
  'datasetDetail.reviewPass': 'Latest version of the dataset has been approved',
  'datasetDetail.rejected': 'Latest version of the dataset has been rejected',
  'datasetDetail.rejectedDesc':
    'Reason for rejection: Contains sensitive information. The content currently shown to community users is still the content of the last approved review',
  'datasetDetail.completeDataset': 'Complete the dataset introduction',
  'datasetDetail.completeDatasetDesc':
    'Complete the dataset introduction and upload the data to make the dataset public',
  'datasetDetail.preview': 'Data preview',
  'datasetDetail.errPreview': 'No data available for preview',
  'datasetDetail.analysis': 'Statistical analysis',
  'datasetDetail.errAnalysis': 'No statistical view available',
  'datasetDetail.formatDis': 'Format distribution',
  'datasetDetail.formatAll': 'Total',
  'datasetDetail.sizeDis': 'Size distribution',
  'datasetDetail.resolutionDis': 'Resolution distribution',
  'datasetDetail.resolutionH': 'Resolution - Height',
  'datasetDetail.resolutionW': 'Resolution - Width',
  'datasetDetail.upload': 'Upload',
  'datasetDetail.delFile': 'Are you sure you want to delete this file?',
  'datasetDetail.delFileContext': 'The file will not be recoverable after deletion',
  'datasetDetail.metaInfo': 'Related metadata',
  'datasetDetail.baseInfo': 'Basic information',
  'datasetDetail.externalDisplay': 'For external display',
  'datasetDetail.openSource': 'Open source agreement',
  'datasetDetail.requiredPublic': 'Required for public datasets',
  'datasetsDetail.tag': 'Tag',
  'datasetsDetail.paperUrl': 'Published paper',
  'datasetsDetail.dateDesc': 'Please enter a standardized release date, refer to the format: YYYY-MM-DD',
  'datasetsDetail.urlMsg': 'Please enter the correct link address',
  'datasets.visibility': 'Dataset visibility',
  'datasets.visibilityDesc':
    'The current dataset status is private, only the creator can view and edit the dataset content {br} Note: Please make sure the introduction is complete, the files are uploaded, and the License is correctly filled out before setting it to public',
  'datasets.visibilityDesc2':
    'The current status of the dataset is public, anyone can view this dataset, but only the creator can edit it {br} Note: Please make sure the introduction is complete, the files are uploaded, and the License is correctly filled out before setting it to public',
  'dataset.setPrivate': 'Set to private',
  'dataset.setPublic': 'Set to public',
  'dataset.delDataset': 'Delete dataset',
  'dataset.confirmDelDataset': 'Are you sure you want to delete the dataset?',
  'dataset.confirmDelDatasetDesc':
    'The dataset and all its related files will be permanently deleted and cannot be undone',
  'dataset.delDatasetDesc':
    'Please note: {br}This delete operation cannot be undone;{br}The dataset and all its related files will be permanently deleted',
  'dataset.notPublic': 'The dataset cannot be made public',
  'dataset.notPublicDesc':
    'Please upload the dataset related files, complete the introduction, and then choose to make it public',
  'dataset.notPublicDesc2':
    'The dataset has been removed. If you want to reapply for publicization, please contact {br}{email}',
  'dataset.notCommitContent': 'Is there uncommitted content?',
  'dataset.notCommitContentDesc':
    'Please submit before making it public. The community users can see the latest submitted content',
  'dataset.taskCompletion': 'Please make public after the task is completed',
  'dataset.taskCompletionDesc':
    'Data is currently being uploaded/unzipped, please make public after the task is completed',
  'dataset.useDataset': 'Use dataset',
  'dataset.cliDown': '{url} Download dataset',
  'dataset.sdkDown': '{url} Download dataset',
  'dataset.editIntroduce': 'Edit dataset introduction',
  'dataset.editIntroduceTip': 'Includes README.md and metafile files',
  'dataset.fileAuditRejected': 'Rejected after Review',
  'dataset.quit': 'Are you sure you want to exit? ',
  'dataset.quitContent': 'The edited content will not be saved after exiting.',
  'datasetDetail.editDatasetIntroduce': 'Edit dataset introduction',
  'dataset.CurrentCatalog': 'Current directory'
};
