import axiosRequest from 'axios';
import type { AxiosInstance } from 'axios';

export let prefix = 'api';
export let axios: AxiosInstance = axiosRequest.create({
  // 有大文件上传 这里设置大点
  timeout: Infinity
  // cancelToken: new axios.CancelToken((cancel: Canceler) => {}),
});

export const setPrefix = async (axiosPrefix: string) => {
  prefix = axiosPrefix;
};

export const setAxios = async (axiosInstance: AxiosInstance) => {
  axios = axiosInstance;
};

export const initAxios = async (axiosInstance: AxiosInstance, axiosPrefix: string) => {
  prefix = axiosPrefix;
  axios = axiosInstance;
};
